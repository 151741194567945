import axios from "axios";
import { localStorageKeys } from "utils/constants/local_storage";
import toastr from "toastr";

const refreshAccessToken = async () => {
  try {
    const token = localStorage.getItem(localStorageKeys.TOKEN);
    const response = await baseAxios().post(`auth/refresh-token`, token);
    if (
      response.code === 400 ||
      response.code === 401 ||
      response.code === 404
    ) {
      localStorage.clear();
      window.location.reload();
    } else {
      localStorage.setItem(
        localStorageKeys.TOKEN,
        JSON.stringify(response.data)
      );
      return response?.data?.accessToken;
    }
  } catch (error) {
    throw error;
  }
};

export const baseAxios = () => {
  try {
    const token = JSON.parse(
      localStorage.getItem(localStorageKeys.TOKEN) || "{}"
    );

    const languageCode =
      localStorage.getItem(localStorageKeys.I18NEXTLNG) === "vi"
        ? "vi-VN"
        : "zh-CN";

    const instance = axios.create({
      baseURL: process.env.REACT_APP_ENDPOINT_API,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
        "Accept-Language": languageCode,
      },
    });

    instance.interceptors.request.use(
      (config) => {
        if (config.data) {
          const haveFile = Object.values(config.data).some(
            (e) => e && e.toString() === "[object File]"
          );

          if (haveFile) {
            config.headers["Content-Type"] =
              "multipart/form-data ; charset=UTF-8";
          }
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(async (response) => {
      if (response.data.isError) {
        if (response.data.code === 401) {
          try {
            const accessToken = await refreshAccessToken();
            response.config.headers.Authorization = `Bearer ${accessToken}`;
            let res = await axios.request(response.config);
            return res.data;
            // return axios.request(response.config);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        } else {
          toastr.error(response.data.message);
        }
      }
      return Promise.resolve(response.data);
    });

    return instance;
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
};
