import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllCurrencyService,
  uploadImageService,
  createCurrenciesService,
  updateCurrenciesService,
  deleteCurrenciesService,
} from "services/currencies.js";

export const getAllCurrencies = createAsyncThunk("getAllCurrency", async (params) => {
  try {
    const res = await getAllCurrencyService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const uploadFileThunk = createAsyncThunk("/files/upload", async (body, thunkAPI) => {
  try {
    let response = await uploadImageService(body);

    return response.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});
export const createCurrencies = createAsyncThunk("/create", async (data, thunkAPI) => {
  try {
    const res = await createCurrenciesService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const updateCurrencies = createAsyncThunk("/update", async (data) => {
  try {
    const res = await updateCurrenciesService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const deleteCurrencies = createAsyncThunk("/delete", async (params) => {
  try {
    const res = await deleteCurrenciesService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
