import { createSlice } from "@reduxjs/toolkit";
import { SlicesName } from "utils/app/constants";
import { getAllCurrencies } from "./thunks";
import { currenciesInitialState } from "./initialState";

export const counterSlice = createSlice({
  name: SlicesName.Currencies,
  initialState: currenciesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL USERS
    builder.addCase(getAllCurrencies.fulfilled, (state, action) => {
      state.allCurrencies.loading = false;
      state.allCurrencies.error = null;
      if (action.payload) {
        state.allCurrencies.data = action.payload?.data;
        state.allCurrencies.success = true;
      }
      state.allCurrencies.filter = {
        ...action.meta.arg,
        total: action.payload?.filter?.total,
      };
    });
    builder.addCase(getAllCurrencies.pending, (state, action) => {
      state.allCurrencies.loading = true;
      state.allCurrencies.error = null;
      state.allCurrencies.success = false;
    });
    builder.addCase(getAllCurrencies.rejected, (state, action) => {
      state.allCurrencies.loading = false;
      state.allCurrencies.error = action.payload;
      state.allCurrencies.success = false;
    });
  },
});

export const { handleOpenModalAccountForm } = counterSlice.actions;

export default counterSlice.reducer;
