export const endpoints = {
  Auth: "/auth",
  User: "/user",
  Currency: "/currency",
  Log: "/log",
  Account: "/accounts",
  Role: "/roles",
  Rooms: "/rooms",
  Booking: "/bookings",
  Files: "/files",
  Departments: "/departments",
};
