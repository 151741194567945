export const SlicesName = {
  Auth: "auth",
  Departments: "departments",
  Users: "users",
  Currencies: "currencies",
  Logs: "logs",
};

export const ReducersName = {
  Auth: "auth",
  Departments: "departments",
  Users: "users",
  Currencies: "currencies",
  Logs: "logs",
};
