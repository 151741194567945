import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";

export const getAllCurrencyService = async (params) => {
  const res = await baseAxios().get(`${endpoints.Currency}/list`, { params });
  return res;
};
export const uploadImageService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Files}/upload/`, data, {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
    },
  });
  return res;
};
export const createCurrenciesService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Currency}/create`, data);
  return res;
};
export const updateCurrenciesService = async (data) => {
  const res = await baseAxios().put(
    `${endpoints.Currency}/update/${data.id}`,
    data
  );
  return res;
};
export const deleteCurrenciesService = async (params) => {
  const res = await baseAxios().delete(
    `${endpoints.Currency}/delete/${params.currencyId}`
  );
  return res;
};
