import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";

export const getAllLogService = async (params) => {
  const res = await baseAxios().get(`${endpoints.Log}/list`, { params });
  return res;
};
export const createLogsService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Log}/create`, data);
  return res;
};
export const updateLogsService = async (data) => {
  const res = await baseAxios().put(`${endpoints.Log}/update/${data.id}`, data);
  return res;
};
export const deleteLogsService = async (params) => {
  const res = await baseAxios().delete(
    `${endpoints.Log}/delete/${params.logId}`
  );
  return res;
};
