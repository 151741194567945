import { createSlice } from "@reduxjs/toolkit";
import { SlicesName } from "utils/app/constants";
import { getAllLogs } from "./thunks";
import { logsInitialState } from "./initialState";

export const counterSlice = createSlice({
  name: SlicesName.Logs,
  initialState: logsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL USERS
    builder.addCase(getAllLogs.fulfilled, (state, action) => {
      state.allLogs.loading = false;
      state.allLogs.error = null;
      console.log("action.payload", action.payload)
      if (action.payload) {
        state.allLogs.data = action.payload?.logs;
        state.allLogs.success = true;
      }
      state.allLogs.filter = {
        ...action.meta.arg,
        total: action.payload?.filter?.total,
      };
    });
    builder.addCase(getAllLogs.pending, (state, action) => {
      state.allLogs.loading = true;
      state.allLogs.error = null;
      state.allLogs.success = false;
    });
    builder.addCase(getAllLogs.rejected, (state, action) => {
      state.allLogs.loading = false;
      state.allLogs.error = action.payload;
      state.allLogs.success = false;
    });
  },
});

export const { handleOpenModalAccountForm } = counterSlice.actions;

export default counterSlice.reducer;
