import { configureStore } from "@reduxjs/toolkit";

import { ReducersName } from "utils/app/constants";
import authReducer from "app/features/auth/authSlice";
import departmentsReducer from "app/features/departments/departmentsSlice";
import usersReducer from "app/features/users/usersSlice";
import currenciesReducer from "app/features/currencies/currenciesSlice";
import logsReducer from "app/features/logs/logsSlice";

const middlewares = [];
if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: {
    [ReducersName.Auth]: authReducer,
    [ReducersName.Departments]: departmentsReducer,
    [ReducersName.Users]: usersReducer,
    [ReducersName.Currencies]: currenciesReducer,
    [ReducersName.Logs]: logsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});
