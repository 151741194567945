import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";

export const loginService = async (data) => {
  const res = await baseAxios().post(`${endpoints.User}/login`, data);
  return res;
};

export const refreshTokenService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Auth}/refresh-token`, data);
  return res;
};
