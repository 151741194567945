import { createSlice } from "@reduxjs/toolkit";
import { SlicesName } from "utils/app/constants";
import { getAllUsers } from "./thunks";
import { usersInitialState } from "./initialState";

export const counterSlice = createSlice({
  name: SlicesName.Users,
  initialState: usersInitialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL USERS
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers.loading = false;
      state.allUsers.error = null;
      if (action.payload?.status === 200) {
        state.allUsers.data = action.payload?.data?.users;
        state.allUsers.success = true;
      }
      state.allUsers.filter = {
        ...action.meta.arg,
        total: action.payload?.filter?.total,
      };
    });
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.allUsers.loading = true;
      state.allUsers.error = null;
      state.allUsers.success = false;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.allUsers.loading = false;
      state.allUsers.error = action.payload;
      state.allUsers.success = false;
    });
  },
});

export const { handleOpenModalAccountForm } = counterSlice.actions;

export default counterSlice.reducer;
