import { baseAxios } from "connections/baseAxios";
import { endpoints } from "utils/constants/endpoints";

export const getAllUserService = async (params) => {
  const res = await baseAxios().get(`${endpoints.User}/list`, { params });
  return res;
};
export const uploadImageService = async (data) => {
  const res = await baseAxios().post(`${endpoints.Files}/upload/`, data, {
    headers: {
      "Content-Type": "multipart/form-data; charset=UTF-8",
    },
  });
  return res;
};
export const createUsersService = async (data) => {
  const res = await baseAxios().post(`${endpoints.User}/create`, data);
  return res;
};
export const updateUsersService = async (data) => {
  const res = await baseAxios().put(
    `${endpoints.User}/update/${data.id}`,
    data
  );
  return res;
};
export const deleteUsersService = async (params) => {
  const res = await baseAxios().delete(
    `${endpoints.User}/delete/${params.accountId}`
  );
  return res;
};
export const changePasswordUserService = async (data) => {
  const res = await baseAxios().put(
    `${endpoints.User}/changePassword/${data.id}`,
    data
  );
  return res;
};
