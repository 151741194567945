import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllUserService,
  uploadImageService,
  createUsersService,
  updateUsersService,
  deleteUsersService,
  changePasswordUserService,
} from "services/users.js";

export const getAllUsers = createAsyncThunk("getAllUser", async (params) => {
  try {
    const res = await getAllUserService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const uploadFileThunk = createAsyncThunk("/files/upload", async (body, thunkAPI) => {
  try {
    let response = await uploadImageService(body);

    return response.data;
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});
export const createUsers = createAsyncThunk("/create", async (data, thunkAPI) => {
  try {
    const res = await createUsersService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const updateUsers = createAsyncThunk("/update", async (data) => {
  try {
    const res = await updateUsersService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const deleteUsers = createAsyncThunk("/delete", async (params) => {
  try {
    const res = await deleteUsersService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const changePasswordUser = createAsyncThunk("/changePassword", async (data) => {
  try {
    const res = await changePasswordUserService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
