import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllLogService,
  createLogsService,
  updateLogsService,
  deleteLogsService,
} from "services/logs.js";

export const getAllLogs = createAsyncThunk("getAllLog", async (params) => {
  try {
    const res = await getAllLogService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const uploadFileThunk = createAsyncThunk(
  "/files/upload",
  async (body, thunkAPI) => {
    return true;
  }
);
export const createLogs = createAsyncThunk(
  "/create",
  async (data, thunkAPI) => {
    try {
      const res = await createLogsService(data);
      return res;
    } catch (err) {
      console.log(err);
    }
  }
);
export const updateLogs = createAsyncThunk("/update", async (data) => {
  try {
    const res = await updateLogsService(data);
    return res;
  } catch (err) {
    console.log(err);
  }
});
export const deleteLogs = createAsyncThunk("/delete", async (params) => {
  try {
    const res = await deleteLogsService(params);
    return res;
  } catch (err) {
    console.log(err);
  }
});
