import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginService, refreshTokenService } from "services/auth";

export const loginThunk = createAsyncThunk('/user/login', async (body, thunkAPI) => {
  try {
    const responseLogin = await loginService(body)
    return responseLogin.data
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})

export const refreshTokenThunk = createAsyncThunk('/auth/refesh-token', async (body, thunkAPI) => {
  try {
    const responseRefreshToken = await refreshTokenService(body)
    return responseRefreshToken.data
  } catch (error) {
    thunkAPI.rejectWithValue(error)
  }
})